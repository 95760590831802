<!-- @Author: Yu_Bo -->
<template>
	<div class='verify_phone zc_dialog_box'>
		<el-dialog :visible.sync="dialogVisible" width="494px" :close-on-click-modal='false'>
		  <div class="title" slot="title">
		    <div class="name">验证手机号</div>
		  </div>
      <div class="main_title">以下手机号将用于接收审核短信，请确认手机号可用</div>
      <div class="main_input">
        <el-form :model="phoneForm" :rules="phoneRules" ref="phoneForm" label-width="0px" class="demo-ruleForm">
          <el-form-item prop="phone">
            <div class="form_text">
              <div class="left">手机号</div>
              <div class="right">
                <el-input disabled v-model="phone" placeholder="请输入手机号"></el-input>
              </div>
            </div>
          </el-form-item>
          <el-form-item prop="code">
            <div class="form_text">
              <div class="left"><span>*</span> 验证码</div>
              <div class="code_text">
                <el-input v-model="phoneForm.code" placeholder="请输入验证码"></el-input>
                <el-button :disabled='disabled' type="text" @click='getCodeBtn'>{{codeTxt}}</el-button>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button size="small" @click="cancelBtn">取 消</el-button>
        <el-button size="small" type="primary" :loading='loading' @click="submitForm('phoneForm')">确 定</el-button>
      </span>
    </el-dialog>
	</div>
</template>

<script>
  import {
    mixinSettle
  } from "@/mixin/settle"
  import { mapState } from 'vuex'
	export default {
    mixins: [mixinSettle],
		components: {},
		props: {},
		data() {
			return{
				dialogVisible:false,
        phoneForm:{
          code:'',
        },
        disabled:false,
        codeTxt:'获取验证码',
        codeTime:60,
        timer:null,
        // 
        loading:false
			}
		},
		computed: {
      ...mapState({
      	phone: state => state.userInfo.user_info.mobile
      }),
    },
		watch: {},
		created() {
    },
		mounted() {},
		methods: {
      // 获取验证码
      getCodeBtn(){
        var that = this
        if(that.disabled){
          return
        }
        var params = {
          mobile:this.phone
        }
        this.$publicApi.sendSms(params).then(res => {
          if (res.code == 1000) {
            if (!this.timer) {
              that.disabled = true
              that.codeTxt = that.codeTime+'s'
              that.timer = setInterval(() => {
                if (that.codeTime > 0 && that.codeTime <= 60) {
                  that.codeTime--
                  that.codeTxt = that.codeTime+'s'
                } else {
                  that.disabled = false
                  that.codeTime = 60
                  that.codeTxt = '重新获取'
                  clearInterval(that.timer)
                  that.timer = null;
                }
              }, 1000)
            }
          } else {
            this.$errMsg(res.message)
          }
        })

      },
      // 打开弹框
      openDialogBtn(){
        this.dialogVisible=true
      },
      // 取消
      cancelBtn(){
        this.dialogVisible=false
      },
      // 确定
      submitForm(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            // 验证通过
            this.submitBtn()
          } else {
            // 验证不通过
            return false;
          }
        });
      },
      submitBtn(){
        this.loading=true
        this.$emit('affirm',this.phoneForm.code)
      },
      // 关闭
      closeBtn(){
        this.loading=false
      },
    },
	}
</script>

<style lang='scss' scoped>
	.verify_phone{
    .main_title{
      width: 100%;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    .main_input{
      width: 100%;
      padding-top: 30px;
      .form_text{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        .left{
          width: 84px;
          padding-right: 20px;
          text-align: right;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333;
          span{
            color: #E00C25;
          }
        }
        .right{
          width: 370px;
          .el-input{
            width: 100%;
            height: 40px;
            line-height: 40px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333;
          }
        }
        .code_text{
          width: 370px;
          height: 40px;
          padding-right: 20px;
          border: 1px solid #DCDFE6;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .el-input{
            width: 250px;
            border: none;
            ::v-deep .el-input__inner{
              border: none;
              background: none;
            }
          }
        }
      }
    }
    ::v-deep .el-form-item{
      margin-bottom: 30px;
      .el-form-item__error{
        left: 90px !important;
      }
    }
    ::v-deep .el-form-item:last-child{
      margin-bottom: 0;
    }
	}
</style>
