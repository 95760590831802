<!-- @Author: Yu_Bo -->
<template>
	<div class='select_identity'>
    <div class="identity_title">请选择您的身份</div>
    <div class="identity_con">
      <div class="con_text" :class="identity==1?'con_active':''" @click="identityBtn(1)">
        <div class="top_right"><i class="el-icon-check" v-if="identity==1"></i></div>
        <img src="@/assets/images/img/settle_header_a.png" alt="">
        <span>我是个人</span>
      </div>
      <div class="con_text" :class="identity==2?'con_active':''" @click="identityBtn(2)">
        <div class="top_right"><i class="el-icon-check" v-if="identity==2"></i></div>
        <img src="@/assets/images/img/settle_header_b.png" alt="">
        <span>我是企业</span>
      </div>
    </div>
    <div class="identity_btn" @click="nextStepBtn">下一步</div>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {},
		data() {
			return{
        identity:1,
			}
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {},
		methods: {
      // 切换
      identityBtn(type){
        this.identity=type
      },
      // 下一步
      nextStepBtn(){
        this.$emit('firstStep',this.identity)
      },
    },
	}
</script>

<style lang='scss' scoped>
	.select_identity{
    width: 100%;
    .identity_title{
      width: 100%;
      padding-top: 70px;
      text-align: center;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .identity_con{
      padding: 30px 0 50px;
      width: 100%;
      display: flex;
      justify-content: center;
      .con_text{
        cursor: pointer;
        position: relative;
        margin: 0 15px;
        width: 240px;
        height: 280px;
        background: #F0F0F0;
        border-radius: 10px;
        .top_right{
          position: absolute;
          top: 15px;
          right: 15px;
          z-index: 1;
          width: 20px;
          height: 20px;
          background: #fff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        img{
          display: block;
          width: 100px;
          height: 100px;
          margin: 70px auto 15px;
          border-radius: 50%;
          -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
        }
        span{
          display: block;
          text-align: center;
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
      }
      .con_active{
        background: #2E4BF2;
        img{
          -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
        }
        span{
          color: #fff;
        }
      }
    }
    .identity_btn{
      cursor: pointer;
      margin: auto;
      width: 118px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      background: #2E4BF2;
      border-radius: 2px;
    }
	}
</style>
