<!-- @Author: Yu_Bo -->
<template>
	<div class='select_under_review'>
		<img class="img_icon" src="@/assets/images/img/under_review.png" alt="">
    <div class="title">正在审核中...</div>
    <div class="tip">您的合伙人信息正在审核中，审核预计需要1-2个工作日，请耐心等待</div>
    <div class="box">
      <div class="txt">如有疑问，请扫描下方二维码联系管理员</div>
      <div class="img">
        <img src="@/assets/images/img/service_img.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
	export default {
		components: {},
		props: {},
		data() {
			return{

			}
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {},
		methods: {},
	}
</script>

<style lang='scss' scoped>
	.select_under_review{
    padding-top: 100px;
    .img_icon{
      display: block;
      width: 174px;
      height: 103px;
      margin: auto;
    }
    .title{
      width: 100%;
      padding-top: 20px;
      font-size: 18px;
      text-align: center;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .tip{
      width: 100%;
      padding-top: 30px;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }
    .box{
      width: 486px;
      padding: 20px 0;
      margin: 40px auto 0;
      background: #F8F8F8;
      .txt{
        width: 100%;
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
      .img{
        width: 120px;
        height: 120px;
        margin: 10px auto 0;
        padding: 10px;
        background: #FFFFFF;
        img{
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
	}
</style>
