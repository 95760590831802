<!-- @Author: Yu_Bo -->
<template>
	<div class='select_user'>
    <div class="user_main">
      <el-form :model="userForm" :rules="userRules" ref="userForm" label-width="0px" class="demo-ruleForm">
        <el-form-item prop="name">
          <div class="form_text">
            <div class="left"><span>*</span> 姓名</div>
            <div class="right">
              <el-input v-model="userForm.name" maxlength="10" placeholder="请输入姓名"></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item prop="idCard">
          <div class="form_text">
            <div class="left"><span>*</span> 身份证</div>
            <div class="right">
              <el-input v-model="userForm.idCard" maxlength="18" placeholder="请输入身份证号"></el-input>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div class="form_btn">
        <span class="btn_left" @click="submitPrev">上一步</span>
        <span class="btn_right" @click="submitForm('userForm')">下一步</span>
      </div>
    </div>
	</div>
</template>

<script>
  import {
    mixinSettle
  } from "@/mixin/settle"
	export default {
    mixins: [mixinSettle],
		components: {},
		props: {},
		data() {
			return{
        userForm:{
          name:'',
          idCard:'',
        }
			}
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {},
		methods: {
      // 上一步
      submitPrev(){
        this.$emit('userPrev')
      },
      // 下一步
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            // 验证通过
            this.submitBtn()
          } else {
            // 验证不通过
            return false;
          }
        });
      },
      submitBtn(){
        var that = this
        if(!that.$testVerify.isCard(that.userForm.idCard)){
          that.$errMsg('请输入正确身份证号')
          return 
        }else{
          that.$emit('userStep',that.userForm)
        }
      },
    },
	}
</script>

<style lang='scss' scoped>
	.select_user{
    padding-top: 70px;
    .user_main{
      width: 460px;
      margin: 0 auto;
    }
    .form_text{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .left{
        width: 100px;
        padding-right: 20px;
        text-align: right;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333;
        span{
          color: #E00C25;
        }
      }
      .right{
        width: 360px;
        .el-input{
          width: 100%;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333;
        }
      }
    }
    .form_btn{
      padding-top: 20px;
      width: 100%;
      display: flex;
      justify-content: center;
      span{
        cursor: pointer;
        display: block;
        margin: 0 10px;
        width: 118px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        border-radius: 2px;
      }
      .btn_left{
        color: #2E4BF2;
        border: 1px solid #2E4BF2;
      }
      .btn_right{
        color: #FFFFFF;
        background: #2E4BF2;
      }
    }
    ::v-deep .el-form-item{
      margin-bottom: 30px;
      .el-form-item__error{
        left: 100px;
      }
    }
	}
</style>
